<template>
	<div>
		<Form style="background: #fff; padding: 16px;" :label-width="100">
			<FormItem label="订单id" style="width: 420px;">
				<Input v-model="search.order_id" placeholder="输入订单id搜索"></Input>
			</FormItem>
			<FormItem label="订单状态" style="width: 420px;">
				<Select v-model="search.status">
					<Option :value="0">全部</Option>
					<Option :value="1">待支付</Option>
					<Option :value="2">已完成</Option>
				</Select>
			</FormItem>
			<FormItem label="下单时间" style="width: 420px;">
				<DatePicker v-model="search.create_time" type="daterange" placeholder="选择时间范围" style="width: 100%;" />
			</FormItem>
			<FormItem>
				<Button @click="init()" type="primary">搜索</Button>
			</FormItem>
		</Form>
		<Table :columns="columns" :data="data" :loading="loading">
			<template #package="{row}">
				<div>套餐名称：{{ row.name }}</div>
				<div>包含次数：{{ row.amount }}</div>
			</template>
			<template #status="{row}">
				<Tag :color="row.status === 1 ? 'default' : 'success'">{{ row.status === 1 ? '待支付' : '已完成' }}</Tag>
			</template>
		</Table>
		<div style="display: flex; justify-content: flex-end; background: #fff; padding: 16px;">
			<Page :total="search.total" :current="search.page" :page-size="search.pageSize" :page-size-opts="[15,30,50]"
				show-total show-sizer @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				search: {
					total: 0,
					pageSize: 15,
					page: 1,
					order_id: '',
					status: 0,
					create_time: []
				},
				loading: false,
				columns: [{
						title: '订单id',
						key: 'order_id'
					},
					{
						title: '套餐信息',
						slot: 'package'
					},
					{
						title: '订单状态',
						slot: 'status'
					},
					{
						title: '下单时间',
						key: 'create_time'
					},
					// { title: '操作', slot: 'operate' }
				],
				data: []
			}
		},
		methods: {
			// 初始化数据
			init() {
				const _this = this;
				this.loading = true;
				this.requestApi('/adm/get_capacitive_cloud_order', {
					search: this.search
				}).then((res) => {
					if (res.status === 1) {
						_this.loading = false;
						_this.data = res.data.data;
						_this.search.total = res.data.total;
					}
				});
			},
			// 页码 改变事件
			pageChange(data) {
				this.search.page = data;
				this.init();
			},
			// 每页条数 改变事件
			pageSizeChange(data) {
				this.search.pageSize = data;
				this.init();
			},
		}
	}
</script>

<style scoped>

</style>